<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb breadcrumb-links breadcrumb-dark">
                <li class="breadcrumb-item">
                  <a href="#" target="_self" class="">
                    <a href="#/" class="active"><i class="fas fa-home"></i> </a>
                  </a>
                </li>
                <li class="breadcrumb-item" style="display: inline-block">
                  <router-link
                    class="active"
                    :to="{
                      name: 'ViewShareProject',
                      params: { id: projectId },
                    }"
                    >Danh sách từ khóa</router-link
                  >
                </li>
                <li
                  class="breadcrumb-item active"
                  style="display: inline-block"
                >
                  <span aria-current="location"
                    ><span data-v-99912124="">{{ keyword }}</span></span
                  >
                </li>
              </ol>
            </nav>
          </nav>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="card mb-4">
        <!-- Card header -->
        <div class="card-header">
          <h3 class="mb-0">Từ khoá "{{ keyword }}"</h3>
        </div>
        <!-- Card body -->
        <div class="card-body">
          <div
            class="row"
            style="
              border-bottom: 1px solid rgba(0, 0, 0, 0.05);
              margin-bottom: 10px;
            "
          >
            <div class="col-md-6">
              <div class="row" style="align-items: baseline">
                <div class="col-md-4">
                  <base-checkbox
                    v-model="showAll"
                    class="mb-3"
                    @change="toggleShowAll()"
                    >[{{ chartOptions.series.length }}]</base-checkbox
                  >
                </div>
                <div class="col-md-8 toggle-top-btn-group">
                  <!-- <base-input input-classes="form-control-sm" placeholder="Search..."/> -->
                  <a
                    href="#"
                    class="btn btn-sm btn-white btn-toggle-active"
                    @click="
                      showAll = !showAll;
                      toggleShowAll();
                    "
                    >Show All</a
                  >
                  <a
                    href="#"
                    class="btn btn-sm btn-white"
                    @click="toggleTop(5, $event)"
                    >5</a
                  >
                  <a
                    href="#"
                    class="btn btn-sm btn-white"
                    @click="toggleTop(10, $event)"
                    >10</a
                  >
                  <a
                    href="#"
                    class="btn btn-sm btn-white"
                    @click="toggleTop(20, $event)"
                    >20</a
                  >
                  <a
                    href="#"
                    class="btn btn-sm btn-white"
                    @click="toggleTop(30, $event)"
                    >30</a
                  >
                  <a
                    href="#"
                    class="btn btn-sm btn-white"
                    @click="toggleTop(50, $event)"
                    >50</a
                  >
                  <a
                    href="#"
                    class="btn btn-sm btn-white"
                    @click="showCurrentDomain($event)"
                    >Me</a
                  >
                </div>
              </div>
            </div>

            <div
              class="col-md-6 d-flex flex-row justify-content-end align-items-baseline"
            >
              <date-range-picker
                ref="picker"
                :opens="'left'"
                :locale-data="localeData"
                :timePicker="false"
                :showWeekNumbers="false"
                :showDropdowns="true"
                :autoApply="true"
                v-model="dateRange"
                :ranges="rangeOptions"
                @toggle="checkOpen"
                @update="applyDateRange"
                :linkedCalendars="true"
                :control-container-class="'form-control form-control-sm datepicker flatpickr-input'"
              >
                <template v-slot:input="picker" style="min-width: 350px">
                  {{ picker.startDate | formatDate }} -
                  {{ picker.endDate | formatDate }} </template
                >i
              </date-range-picker>

              <base-button
                class="view btn-icon ml-1"
                type="primary"
                size="sm"
                icon
                @click="getTerm"
              >
                <font-awesome-icon icon="filter" inverse /> Lọc
              </base-button>
            </div>
          </div>
          <!-- Form groups used in grid -->
          <div class="row">
            <div class="col-md-2">
              <template v-for="(data, index) in chartOptions.series">
                <base-checkbox
                  :key="index"
                  v-model="chartOptions.series[index].visible"
                  class="mb-1 chart-checkbox"
                  :id="'serie-label-' + data.name"
                >
                  <span :style="{ color: colors[index] }">{{
                    index + 1 + ". " + data.name
                  }}</span>
                </base-checkbox>
              </template>
            </div>
            <div class="col-md-10">
              <highcharts class="hc" :options="chartOptions"></highcharts>
            </div>
          </div>

          <div class="row">
            <div class="card w-100 mt-3">
              <div class="card-header border-0">
                <div class="row">
                  <div class="col-6">
                    <h3 class="mb-0">Lịch sử thứ hạng</h3>
                  </div>
                </div>
              </div>

              <el-table
                class="table-responsive align-items-center table-flush table-striped"
                header-row-class-name="thead-light"
                :data="history"
              >
                <el-table-column
                  label="Ngày"
                  prop="updated_at"
                  min-width="140px"
                  sortable
                >
                  <template v-slot="{ row }">
                    {{ row.updated_at | formatDateTime }}
                  </template>
                </el-table-column>

                <el-table-column
                  label="Vị trí"
                  min-width="200px"
                  prop="rank"
                  sortable
                >
                  <template v-slot="{ row }">
                    {{ row.rank ? row.rank : "Không tìm thấy" }}
                  </template>
                </el-table-column>

                <el-table-column
                  label="Link tìm thấy"
                  min-width="200px"
                  prop="rank"
                  sortable
                >
                  <template v-slot="{ row }">
                    {{ row.found_url ? row.found_url : "N/A" }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal :show.sync="showDetailModal" size="lg">
      <div class="row">
        <div class="col-sm-6">
          <span :style="{ padding: '5px', background: trackingDetail.color }"
            >SERP Rank: {{ trackingDetail.rank }}</span
          >
        </div>
        <div class="col-sm-6">Ngày: {{ trackingDetail.date }}</div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <p>
            <strong>Domain</strong>:
            <a :href="trackingDetail.label">{{ trackingDetail.label }}</a>
          </p>
          <p><strong>Title</strong>: {{ trackingDetail.title }}</p>
          <p>
            <strong>URL</strong>:
            <a :href="trackingDetail.url">{{ trackingDetail.url }}</a>
          </p>
          <p><strong>Description</strong>: {{ trackingDetail.description }}</p>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import _ from "lodash";
import { parseTime, formatNumberWithCommas } from "@/utils";
import { Table, TableColumn } from "element-ui";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

const colors = [
  "#00EE00",
  "#00ae4e",
  "#007676",
  "#1cacff",
  "#0077dd",
  "#114499",
  "#BB66FF",
  "#7e00c6",
  "#610098",
  "#FF2288",
  "#FF0000",
  "#DD1155",
  "#AA0044",
  "#a95b07",
  "#d59412",
  "#ffb728",
  "#ff562f",
  "#de3c17",
  "#ad2707",
  "#007722",
  "#12cba6",
  "#2789a8",
  "#80751e",
  "#8037a2",
  "#69cc5e",
  "#4415ed",
  "#ff4ddd",
  "#93452c",
  "#2facca",
  "#55d7fd",
  "#c3c3c3",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#d3cace",
  "#c3c3c3",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#d3cace",
  "#c3c3c3",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#d3cace",
  "#c3c3c3",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#d3cace",
  "#c3c3c3",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#d3cace",
  "#c3c3c3",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#d3cace",
  "#c3c3c3",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#d3cace",
  "#c3c3c3",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#d3cace",
  "#c3c3c3",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#d3cace",
  "#c3c3c3",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#d3cace",
  "#c3c3c3",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#d3cace",
  "#c3c3c3",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#d3cace",
  "#c3c3c3",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#d3cace",
  "#c3c3c3",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#d3cace",
  "#c3c3c3",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#d3cace",
  "#c3c3c3",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#d3cace",
  "#c3c3c3",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#d3cace",
  "#c3c3c3",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#d3cace",
  "#c3c3c3",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#d3cace",
  "#c3c3c3",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#d3cace",
  "#c3c3c3",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#d3cace",
  "#c3c3c3",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#d3cace",
  "#c3c3c3",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#d3cace",
  "#c3c3c3",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#d3cace",
  "#c3c3c3",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#d3cace",
  "#c3c3c3",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#d3cace",
  "#c3c3c3",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#d3cace",
  "#c3c3c3",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#d3cace",
  "#c3c3c3",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#d3cace",
  "#c3c3c3",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#d3cace",
  "#c3c3c3",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
  "#a38995",
  "#b29ba5",
  "#c1b0b7",
];

var domainDetails = [];

export default {
  name: "ViewShareKeyword",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    DateRangePicker,
  },
  data() {
    let startDate = new Date();
    let endDate = new Date();
    let startDate30Days = new Date();
    let startDate60Days = new Date();
    let startDate180Days = new Date();
    startDate.setDate(endDate.getDate() - 15);
    startDate30Days.setDate(endDate.getDate() - 30);
    startDate60Days.setDate(endDate.getDate() - 60);
    startDate180Days.setDate(endDate.getDate() - 180);

    return {
      picker: {
        startDate: startDate,
        endDate: endDate,
      },
      dateRange: { startDate, endDate },
      localeData: {
        direction: "ltr",
        format: "dd/mm/yyyy",
        separator: " - ",
        applyLabel: "Chọn",
        cancelLabel: "Huỷ",
        weekLabel: "Tuần",
        customRangeLabel: "Tuỳ chọn ngày",
        daysOfWeek: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
        monthNames: [
          "Th1",
          "Th2",
          "Th3",
          "Th4",
          "Th5",
          "Th6",
          "Th7",
          "Th8",
          "Th9",
          "Th10",
          "Th11",
          "Th12",
        ],
        firstDay: 1,
      },
      rangeOptions: {
        "15 ngày": [startDate, endDate],
        "1 tháng": [startDate30Days, endDate],
        "3 tháng": [startDate60Days, endDate],
        "6 tháng": [startDate180Days, endDate],
      },
      opens: false,
      colors: colors,
      keyword: "",
      term: {},
      projectId: null,
      trackingDetail: {},
      history: [],
      domainDetails: [],
      query: {
        time: "last_15_days",
        startDate: null,
        endDate: null,
      },
      showAll: true,
      showDetailModal: false,
      chartOptions: {
        title: {
          text: "",
        },
        // colors: colors,
        chart: {
          styledMode: false,
          height: 2772.34,
        },
        legend: {
          enabled: false,
        },
        xAxis: {
          categories: [],
          gridLineWidth: 1,
          startOnTick: true,
          labels: {
            align: "left",
          },
          zoomEnabled: true,
          tickmarkPlacement: "on",
          left: 0,
        },
        yAxis: {
          title: {
            text: null,
          },
          min: 1,
          max: 100,
          reversed: true,
          tickInterval: 1,
          showEmpty: true,
          // height: 2772.34
        },
        series: [],
        plotOptions: {
          series: {
            cursor: "pointer",
            point: {
              events: {
                click: (e) => {
                  const trackings = Object.values(this.term).filter(function (
                    item
                  ) {
                    return (
                      item && item.label && item.label === e.point.series.name
                    );
                  });

                  if (trackings.length) {
                    this.trackingDetail = trackings[0];
                  }
                  this.trackingDetail.date = e.point.category;
                  this.trackingDetail.rank = e.point.y;
                  this.trackingDetail.color = e.point.color;
                  this.showDetailModal = true;
                },
                mouseOver: (e) => {
                  let domain = e.target.series.name;
                  let element = document.getElementById(
                    "serie-label-" + domain
                  );

                  if (element) {
                    element.style.background = "#fff2a8";
                  }
                },
                mouseOut: (e) => {
                  let domain = e.target.series.name;
                  let element = document.getElementById(
                    "serie-label-" + domain
                  );

                  if (element) {
                    element.style.background = "none";
                  }
                },
              },
            },
            marker: {
              lineWidth: 1,
            },
          },
        },
        tooltip: {
          useHTML: true,
          formatter: function () {
            const domainDetail = domainDetails[this.series.name];

            let text = '<div class="chart-tooltip-container container">';
            text +=
              "<div class='row'><div class='col-md-12'><b>Vị trí:</b> #" +
              this.point.y +
              "<br/> <b>Domain:</b> " +
              this.series.name +
              "<br/> <b>Ngày:</b>" +
              this.point.category +
              "</div></div><br/>";

            if (
              domainDetail &&
              Object.entries(domainDetail.DD_detail).length > 0
            ) {
              const dd_detail = domainDetail.DD_detail;
              text += `<div class="row mb-2">
                  <div class="col-md-6">
                    <div><strong>M.BL</strong>: ${formatNumberWithCommas(
                      dd_detail.mozLinks
                    )}</div>
                    <div><strong>DA</strong>: ${dd_detail.mozDA}</div>
                    <div><strong>PA</strong>: ${dd_detail.mozPA}</div>
                    <div><strong>M.rank</strong>: ${dd_detail.mozRank}</div>
                  </div>
                  <div class="col-md-6">
                    <div><strong>Ma.BL</strong>: ${formatNumberWithCommas(
                      dd_detail.majesticLinks
                    )}</div>
                    <div><strong>RD</strong>: ${formatNumberWithCommas(
                      dd_detail.majesticRefDomains
                    )}</div>
                    <div><strong>CF</strong>: ${dd_detail.majesticCF}</div>
                    <div><strong>TF</strong>: ${dd_detail.majesticTF}</div>
                  </div>
                </div>`;
            }

            if (
              domainDetail &&
              Object.entries(domainDetail.SR_detail).length > 0
            ) {
              const sr_detail = domainDetail.SR_detail;
              text += `<div class="row">
                  <div class="col-md-6">
                    <div><strong>DA</strong>: ${sr_detail.da}</div>
                    <div><strong>PA</strong>: ${sr_detail.pa}</div>
                    <div><strong>M.rank</strong>: ${sr_detail.mozrank}</div>
                    <div><strong>Links</strong>: ${formatNumberWithCommas(
                      sr_detail.links
                    )}</div>
                    <div><strong>Equity</strong>: ${formatNumberWithCommas(
                      sr_detail.equity
                    )}</div>
                  </div>
                  <div class="col-md-6">
                    <div><strong>S.Rank</strong>: ${formatNumberWithCommas(
                      sr_detail.sr_rank
                    )}</div>
                    <div><strong>Key.Rank</strong>: ${formatNumberWithCommas(
                      sr_detail.sr_kwords
                    )}</div>
                    <div><strong>S.BL</strong>: ${formatNumberWithCommas(
                      sr_detail.sr_ulinks
                    )}</div>
                    <div><strong>S.DBL</strong>: ${formatNumberWithCommas(
                      sr_detail.sr_dlinks
                    )}</div>
                  </div>
                </div>`;
            }
            text += "</div>";
            return text;
          },
          backgroundColor: "rgba(0, 0, 0, .75)",
          borderWidth: 2,
          style: {
            color: "#CCCCCC",
          },
        },
      },
    };
  },
  created() {
    this.getTerm();
  },
  filters: {
    formatDateTime(date) {
      return parseTime(date, "{d}-{m}-{y} {h}:{i}:{s}");
    },
    formatDate(date) {
      return parseTime(date, "{d}-{m}-{y}");
    },
  },
  methods: {
    async getTerm() {
      this.$preloaders.open();

      if (this.query.startDate === null && this.query.endDate === null) {
        this.query.startDate = parseTime(
          this.dateRange.startDate,
          "{y}-{m}-{d}"
        );
        this.query.endDate = parseTime(this.dateRange.endDate, "{y}-{m}-{d}");
      }

      let self = this;
      try {
        const data = await this.$store.dispatch("term/fetchShareKeyword", {
          id: self.$route.params.id,
          query: self.query,
        });
        this.keyword = data.keyword;
        this.term = data.trackings;
        this.projectId = data.term_project_id;
        this.history = data.history;
        domainDetails = data.domain_detail;
        this.chartOptions.xAxis.categories = data.labels;
        // this.chartOptions.yAxis.height = data.trackings.length * 28;
        this.chartOptions.chart.height = data.trackings.length * 28;
        // this.chartOptions.colors = self.colors;
        const found_url = data.tracking.found_url;
        this.trackingDetail = { found_url: found_url };
        this.chartOptions.series = data.trackings.map(function (
          tracking,
          index
        ) {
          let serieData = {
            name: tracking.label,
            data: tracking.data,
            visible: true,
            allowPointSelect: true,
          };

          if (found_url && found_url.indexOf(tracking.label) != -1) {
            // Overide color index for current found domain
            self.colors[index] = "#fb6340";
            serieData["color"] = "#fb6340";
            serieData["shadow"] = {
              color: "#fb6340",
              width: 15,
            };
          } else {
            serieData["color"] = self.colors[index];
          }

          return serieData;
        });
      } catch (e) {
        console.log(e);
      }

      this.$preloaders.close();
    },
    toggleShowAll() {
      Array.from(document.querySelectorAll(".toggle-top-btn-group a")).forEach(
        function (el) {
          el.classList.remove("btn-toggle-active");
        }
      );

      if (this.showAll) {
        _.map(this.chartOptions.series, function (dataset) {
          dataset.visible = true;
          return dataset;
        });

        document
          .querySelector(".toggle-top-btn-group a:first-child")
          .classList.add("btn-toggle-active");
      } else {
        _.map(this.chartOptions.series, function (dataset) {
          dataset.visible = false;
          return dataset;
        });

        document
          .querySelector(".toggle-top-btn-group a:first-child")
          .classList.remove("btn-toggle-active");
      }
    },
    toggleTop(number, event) {
      Array.from(document.querySelectorAll(".toggle-top-btn-group a")).forEach(
        function (el) {
          el.classList.remove("btn-toggle-active");
        }
      );
      event.target.classList.add("btn-toggle-active");
      this.showAll = false;

      _.map(this.chartOptions.series, function (dataset, index) {
        dataset.visible = index < number ? true : false;
        return dataset;
      });
    },
    showCurrentDomain(event) {
      Array.from(document.querySelectorAll(".toggle-top-btn-group a")).forEach(
        function (el) {
          el.classList.remove("btn-toggle-active");
        }
      );
      event.target.classList.add("btn-toggle-active");
      this.showAll = false;

      let currentDomainIndex = null;
      const series = this.chartOptions.series;
      for (let i = 0; i < series.length; i++) {
        if (
          this.trackingDetail.found_url &&
          this.trackingDetail.found_url.indexOf(series[i].name) !== -1
        ) {
          currentDomainIndex = i;
          break;
        }
      }

      _.map(this.chartOptions.series, function (dataset, index) {
        dataset.visible =
          currentDomainIndex && currentDomainIndex == index ? true : false;
        return dataset;
      });
    },
    checkOpen() {},
    applyDateRange(value) {
      this.query.startDate = parseTime(value.startDate, "{y}-{m}-{d}");
      this.query.endDate = parseTime(value.endDate, "{y}-{m}-{d}");
    },
  },
};
</script>

<style>
.custom-checkbox .custom-control-input ~ .custom-control-label {
  font-size: 0.6rem;
  padding-top: 5px;
  word-break: break-all;
}

.custom-checkbox .custom-control-input ~ .custom-control-label span {
  width: 122px;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: unset;
  display: block;
  white-space: nowrap;
}

.toggle-top-btn-group .btn-toggle-active {
  color: #fff;
  background-color: #5e72e4;
  border-color: #5e72e4;
}

.chart-tooltip-container {
  padding: 10px;
  width: 100%;
  min-width: 250px;
  display: block;
}

.chart-tooltip-container .row {
  margin-right: 10px;
}
</style>
